import { generateClient } from 'aws-amplify/api'
import { listTasks } from '../graphql/queries'
import { TypeTask } from '../models'
import { StatusTask } from '../models'
import { OrganizationService } from './OrganizationService'
import moment from 'moment'
import { FORMAT } from '../interfaces/user-provider.interface'
import { getReportPdfMutation } from '../graphql/mutations'
import { createFileOnMobile } from '../hooks/handleMobileDownload'
import { Platform } from 'react-native'
import QuickChart from 'quickchart-js'

interface ITasksGroupedByProject {
    [index: string]: ItemTasksByProject
}

interface ItemTasksByProject {
    duration: moment.Duration
    name_project: string
    projectColor: string
    porcentage: string
}

export const ReportDetailService = () => {
    const headersExportPdf = [
        { header: 'Date', dataKey: 'date' },
        { header: 'Name', dataKey: 'name_project' },
        { header: 'Duration', dataKey: 'range_duration' },
        { header: 'Pomodoro', dataKey: 'pomodoro' },
    ]
    const headersExportCsv = [
        { label: 'Date', key: 'date' },
        { label: 'Name', key: 'name' },
        { label: 'Project', key: 'project' },
        { label: 'Time', key: 'time' },
        { label: 'Duration', key: 'duration' },
        { label: 'Pomodoro', key: 'pomodoro' },
    ]

    const getTasksByDateRange = async (dateRange: any, userSub: string) => {
        try {
            let organizationId = await OrganizationService().getDefaultOrganizationId(userSub)

            const tasks: any = await generateClient().graphql({
                query: listTasks,
                variables: {
                    limit: 2000,
                    filter: { organizationID: { eq: organizationId }, createdAt: { between: dateRange } },
                },
            })
            return tasks?.data?.listTasks?.items
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const timeToSecond = (time: string) => {
        const [hours, minutes, seconds] = time.split(':')
        return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)
    }

    const getTasksReportDetails = async (dateRange: any, userSub: string, timeFormat: FORMAT) => {
        try {
            let tasks = await getTasksByDateRange(dateRange, userSub)
            let newList = tasks
                ?.filter(t => !t._deleted && t.type === TypeTask.FOCUS)
                .map(item => {
                    return {
                        id: item.id,
                        name: item.name,
                        type: item.type,
                        createdDate: item.createdAt,
                        createdDateFormat: new Date(item.createdAt),
                        createdOnlyDate: moment(item.createdAt).format('YYYY-MM-DD'),
                        projectID: item.projectID,
                        projectColor: item.project?.groupcolor,
                        projectName: item.project?.name || '',
                        clientID: item.project?.client?.id,
                        clientName: item.project?.client?.name,
                        userSub: item.usersub,
                        arrayTags: item.TagsTask.items
                            .filter(e => !e._deleted)
                            .map(e => {
                                return { id: e.tag.id, name: e.tag.name, code: e.tag.name, color: e.tag.color }
                            }),
                        pomodoroTime: item.status === StatusTask.COMPLETED ? 1 : 0,
                        time: item.time,
                        status: item.status,
                        timeRange:
                            moment(item.createdAt).format(timeFormat) +
                            ' - ' +
                            moment(item.createdAt).add(timeToSecond(item.time), 'seconds').format(timeFormat),
                        hasTimeEdited: item.hasTimeEdited,
                        _version: item._version,
                        _lastChangedAt: item._lastChangedAt,
                    }
                })

            newList.sort((a, b) => {
                return moment(b._lastChangedAt).diff(a._lastChangedAt)
            })

            return newList
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const groupedTask = (tasks: Array<any>, format: FORMAT) => {
        try {
            if (!tasks.length) return []

            let taskGroup = new Array<any>()

            for (const task of tasks) {
                const taskFound = taskGroup.find(
                    e =>
                        e.name === task.name &&
                        e.projectName === task.projectName &&
                        e.createdOnlyDate === task.createdOnlyDate
                )

                if (taskFound) {
                    taskFound.duration =
                        task.type === TypeTask.FOCUS
                            ? moment.duration(task.time).add(taskFound.duration)
                            : moment.duration('00:00:00').add(taskFound.duration)
                    taskFound.durationbreak =
                        task.type !== TypeTask.FOCUS
                            ? moment.duration(task.time).add(taskFound.durationbreak)
                            : moment.duration('00:00:00').add(taskFound.durationbreak)
                    taskFound.hasTimeEdited = !taskFound.hasTimeEdited ? task.hasTimeEdited : true
                    taskFound.tasks.push(task)
                } else {
                    const newGroup: any = {
                        id: task.id,
                        name: task.name,
                        createdDate: task.createdDate,
                        projectID: task.projectID,
                        projectName: task.projectName ?? '',
                        _lastChangedAt: task.lastChangedAt,
                        createdOnlyDate: task.createdOnlyDate,
                        projectColor: task.projectColor,
                        createdDateFormat: moment(task.createdDate).format('MMM DD, YYYY'),
                        pomodoroTime: 0,
                        breakTime: 0,
                        duration:
                            task.type === TypeTask.FOCUS ? moment.duration(task.time) : moment.duration('00:00:00'),
                        durationbreak:
                            task.type !== TypeTask.FOCUS ? moment.duration(task.time) : moment.duration('00:00:00'),
                        durationFormat: '',
                        durationbreakFormat: '',
                        tasks: [],
                        timeRange: '',
                        hasTimeEdited: task.hasTimeEdited,
                    }
                    newGroup.tasks.push(task)
                    taskGroup.push(newGroup)
                }
            }

            taskGroup = taskGroup.map(p => {
                return {
                    ...p,
                    durationFormat: moment.utc(p.duration.asMilliseconds()).format('HH:mm:ss'),
                    durationbreakFormat: moment.utc(p.durationbreak.asMilliseconds()).format('HH:mm:ss'),
                    pomodoroTime: p.tasks.filter(e => e.type === TypeTask.FOCUS && e.status === StatusTask.COMPLETED)
                        .length,
                    breakTime: p.tasks?.filter(e => e.type !== TypeTask.FOCUS).length,
                    tasks:
                        p.tasks.length <= 1 /// Si solo hay una tarea esta misma se renderiza 2 veces, por eso si hay 1 devuelvo el [] vacio
                            ? []
                            : p.tasks?.sort((a, b) =>
                                  a.createdDateFormat > b.createdDateFormat
                                      ? 1
                                      : a.createdDateFormat < b.createdDateFormat
                                      ? -1
                                      : 0
                              ),
                    timeRange: `${moment(p.createdDate).format(format)} - ${moment(p.tasks.at(-1).createdDate)
                        .add(timeToSecond(p.tasks.at(-1).time), 'seconds')
                        .format(format)}`,
                }
            })
            return taskGroup
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const resumeReportDetails = groupedTasks => {
        let totalTime = moment.duration(0)
        let totalPomodoro = 0

        for (const groupTask of groupedTasks) {
            totalTime = totalTime.add(moment.duration(groupTask.durationFormat))
            totalPomodoro += groupTask.pomodoroTime
        }

        return {
            taskTotalTime:
                groupedTasks.length > 0
                    ? `${totalTime.hours() + totalTime.days() * 24}:${totalTime.minutes()}:${totalTime.seconds()}`
                    : '00:00:00',
            totalPomodoro,
        }
    }

    const transformDataToExport = groupedTasks => {
        const tasksDetails = [] as any[]
        const minutesByHour = 60
        let totalDuration = moment.duration(0)

        groupedTasks.map(item => {
            totalDuration = totalDuration.add(moment.duration(item.durationFormat))

            const newItem = {
                date: item.createdDateFormat,
                name: item.name.length ? item.name : 'NO NAME',
                name_project: item.projectName ?? 'NO-PROJECT',
                duration: item.durationFormat,
                range_duration: item.durationFormat + '\n' + item.timeRange,
                pomodoro: item.pomodoroTime,
                projectColor: item.projectColor ? `#${item.projectColor}` : '#000000',
            }

            tasksDetails.push(newItem)

            item.tasks.map(subItem => {
                const newItem = {
                    date: '',
                    name: subItem.name,
                    name_project: item.projectName ?? 'NO-PROJECT',
                    duration: subItem.time,
                    range_duration: subItem.time + '\n' + subItem.timeRange,
                    pomodoro: subItem.pomodoroTime,
                    projectColor: item.projectColor ? `#${item.projectColor}` : '#000000',
                }

                tasksDetails.push(newItem)
            })
        })

        const totalDurationToMinutes =
            (totalDuration.hours() + totalDuration.days() * 24) * minutesByHour + totalDuration.minutes()

        return tasksDetails.map(detail => {
            const duration = moment.duration(detail.duration)
            return {
                ...detail,
                porcentage: !detail.date.length
                    ? ''
                    : totalDurationToMinutes
                    ? (
                          (((duration.hours() + duration.days() * 24) * minutesByHour + duration.minutes()) /
                              totalDurationToMinutes) *
                          100
                      ).toFixed(2) + '%'
                    : '1',
            }
        })
    }

    const handleWebDownload = (data: string) => {
        const pdfBlob = new Blob([Uint8Array.from(atob(data), c => c.charCodeAt(0))], {
            type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(pdfBlob)
        link.download = `Report_detailed_${moment().format('MM/DD/YYYY')}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const DEFAULT_DURATION = '00:00:00'

    const parserDataForPdfChart = (tasks: { date: string; duration: string }[], initDate: Date, endDate: Date) => {
        const diffDays = Math.abs(moment(initDate).diff(moment(endDate), 'days'))
        const newDate = new Date(initDate.getFullYear(), initDate.getMonth(), initDate.getDate())
        let allTasksByRangeDate = {}

        for (let i = 0; i <= diffDays; i++) {
            const currentDate = moment(newDate)
            const objectLabel = currentDate.format('ddd, MMM DD')

            allTasksByRangeDate = {
                ...allTasksByRangeDate,
                [objectLabel]: [{ duration: DEFAULT_DURATION, date: currentDate }],
            }
            newDate.setDate(newDate.getDate() + 1)
        }

        const groupedTasksByDays = tasks
            .filter(task => task.date)
            .reduce((acc: any, item: { date: string; duration: string }) => {
                const currentDate = moment(item.date)
                const objectLabel = currentDate.format('ddd, MMM DD')

                if (acc[objectLabel] && Array.isArray(acc[objectLabel])) {
                    acc[objectLabel].push(item)
                    return acc
                }

                acc[objectLabel] = [item]
                return acc
            }, {})

        return Object.entries({ ...allTasksByRangeDate, ...groupedTasksByDays }).map(([label, tasks]) => {
            const newTasks = tasks as { date: string; duration: string }[]

            const { hours, minutes } = newTasks.reduce(
                (acc, task) => {
                    const [hours, minutes, _seconds] = task.duration.split(':')
                    acc.hours += +hours
                    acc.minutes += +minutes
                    return acc
                },
                { hours: 0, minutes: 0 }
            )

            const duration = moment.utc({ hours }).add(minutes, 'minutes').format('HH:mm:ss')

            return { label, hours, minutes, duration }
        })
    }

    const getTasksGroupedByProjects = (exportData: any[]) => {
        let totalDuration = moment.duration(0)

        const groupedTasks: ITasksGroupedByProject = exportData.reduce((acc, item) => {
            totalDuration = totalDuration.add(moment.duration(item.duration))

            if (!acc[item.name_project]) {
                acc[item.name_project] = {
                    duration: moment.duration(item.duration),
                    name_project: item.name_project.length ? item.name_project : 'NO PROJECT',
                    projectColor: item.projectColor ? item.projectColor : '#000000',
                }
                return acc
            }

            acc[item.name_project] = {
                ...acc[item.name_project],
                duration: moment.duration(item.duration).add(moment.duration(acc[item.name_project].duration)),
            }
            return acc
        }, {})

        const minutesByHour = 60
        const totalDurationToMinutes =
            // {totalDuration.days() * 24} -> because the "total duration" can exceed 24 hours, so the total number of the days its multiplied by 24 hours
            (totalDuration.hours() + totalDuration.days() * 24) * minutesByHour + totalDuration.minutes()

        return Object.values(groupedTasks).map(item => ({
            ...item,
            duration: `${
                item.duration.hours() + item.duration.days() * 24
            }:${item.duration.minutes()}:${item.duration.seconds()}`,
            porcentage: totalDurationToMinutes
                ? (
                      (((item.duration.hours() + item.duration.days() * 24) * minutesByHour + item.duration.minutes()) /
                          totalDurationToMinutes) *
                      100
                  ).toFixed(2) + '%'
                : '1',
        }))
    }

    const generateGeneralChart = (parserData: any[]) => {
        const chart = new QuickChart()
        chart.setWidth(500)
        chart.setHeight(300)
        chart.setVersion('2.9.4')

        chart.setConfig({
            type: 'bar',
            data: {
                labels: parserData.map(item => item.label),
                datasets: [
                    {
                        backgroundColor: '#3dc86e',
                        data: parserData.map(item => item.hours),
                        metadata: { parserData, DEFAULT_DURATION },
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                fontSize: 10,
                                minRotation: 30,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                callback: (val: string) => {
                                    return `${val}h`
                                },
                            },
                        },
                    ],
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'center',
                        color: '#000',
                        borderRadius: 5,
                        backgroundColor: (ctx: any) => {
                            const { parserData, DEFAULT_DURATION } = ctx.dataset.metadata
                            const duration = parserData[ctx.dataIndex].duration
                            return duration === DEFAULT_DURATION ? '' : '#eee'
                        },
                        formatter: (_value: number, ctx: any) => {
                            const { parserData, DEFAULT_DURATION } = ctx.dataset.metadata
                            const duration = parserData[ctx.dataIndex].duration
                            return duration === DEFAULT_DURATION ? '' : `${duration}h`
                        },
                    },
                },
            },
        })

        return chart.getUrl()
    }

    const generateProjectChart = (data: ItemTasksByProject[], totalTime: string) => {
        const chart = new QuickChart()
        chart.setWidth(200)
        chart.setHeight(200)
        chart.setVersion('2.9.4')

        chart.setConfig({
            type: 'doughnut',
            data: {
                datasets: [
                    {
                        data: data.map(item => item.porcentage.replace('%', '')),
                        backgroundColor: data.map(item => item.projectColor),
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    doughnutlabel: {
                        labels: [{ text: totalTime, color: '#000', font: { size: 12 } }],
                    },
                },
            },
        })

        return chart.getUrl()
    }

    const generateTasksChart = (data: any[], totalTime: string) => {
        const chart = new QuickChart()
        chart.setWidth(200)
        chart.setHeight(200)
        chart.setVersion('2.9.4')

        chart.setConfig({
            type: 'doughnut',
            data: {
                datasets: [
                    {
                        data: data.map(item => item.porcentage.replace('%', '')),
                        backgroundColor: data.map(item => item.projectColor),
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    doughnutlabel: {
                        labels: [{ text: totalTime, color: '#000', font: { size: 12 } }],
                    },
                },
            },
        })

        return chart.getUrl()
    }

    const exportDetailsPdf = async (exportData: any[], dateRange: string[], totalTime: any, workSpaceName: string) => {
        const parserData = parserDataForPdfChart(exportData, new Date(dateRange[0]), new Date(dateRange[1]))
        if (!parserData?.length) return

        const tasksGroupedByProjects = getTasksGroupedByProjects(exportData)
        const generalChartImage = generateGeneralChart(parserData)
        const imageProjectChart = generateProjectChart(
            tasksGroupedByProjects as unknown as ItemTasksByProject[],
            totalTime
        )
        const imageTasksChart = generateTasksChart(exportData, totalTime)

        const request: any = await generateClient().graphql({
            query: getReportPdfMutation,
            variables: {
                input: {
                    reportData: exportData,
                    tasksByProjects: tasksGroupedByProjects as any,
                    dateRange,
                    totalTime: `${totalTime}h`,
                    workSpaceName,
                    headerTitle: 'Detailed report',
                    isDetailed: true,
                    imageGeneralReport: generalChartImage,
                    imageProjectChart,
                    imageTasksChart,
                },
            },
        })

        const data = request?.data?.getReportPdfMutation

        if (Platform.OS === 'web') {
            handleWebDownload(data)
        } else
            createFileOnMobile(
                data,
                `Report_detailed_${moment().format('MM/DD/YYYY')}.pdf`,
                'Report download successfully'
            )
    }

    const exportDetailCsv = (exportData: any) => {
        return {
            data: exportData,
            headers: headersExportCsv,
            filename: 'Report_Details.csv',
        }
    }

    return {
        getTasksByDateRange,
        getTasksReportDetails,
        groupedTask,
        resumeReportDetails,
        transformDataToExport,
        exportDetailsPdf,
        exportDetailCsv,
    }
}
